<template>
  <div class="page">
    <div class="header">
      <a-select
        :dropdownMatchSelectWidth="false"
        @select="changeVersionlist"
        allowClear
        showSearch
        :dropdownMenuStyle="{'max-height': '500px'}"
        size="small"
        style="margin: 5px; width: 100px"
        v-model="params.kindId"
        placeholder="请选择大币种"
      >
        <a-select-option
          v-for="items of bigList"
          :key="items.kindId"
          :value="items.kindId"
          >{{ items.kindName }}</a-select-option
        >
      </a-select>
      <a-select
        :dropdownMatchSelectWidth="false"
        @select="selectVersion"
        allowClear
        @search="getVersitionList"
        showSearch
        :dropdownMenuStyle="{'max-height': '500px'}"
        :filter-option="untils.filterOption"
        size="small"
        style="margin: 5px; width: 150px"
        v-model="params.versionId"
        placeholder="请选择版别"
      >
        <a-select-option
          v-for="items of smallList"
          :key="items.versionId"
          :value="items.versionId"
          >{{ items.versionName }}</a-select-option
        >
      </a-select>
      <a-select
        :dropdownMatchSelectWidth="false"
        size="small"
        style="margin: 5px; width: 120px"
        v-model="params.platform"
        allowClear
        placeholder="评级公司"
      >
        <a-select-option value="PCGS">PCGS</a-select-option>
        <a-select-option value="GB">公博</a-select-option>
      </a-select>
      <a-select
        :dropdownMatchSelectWidth="false"
        size="small"
        style="margin: 5px; width: 120px"
        v-model="params.displayType"
        allowClear
        placeholder="展示类型"
      >
        <a-select-option :value="0">曲线</a-select-option>
        <a-select-option :value="1">散点</a-select-option>
      </a-select>
      <a-input
        allowClear
        v-model="params.score"
        size="small"
        style="width: 150px; margin: 5px"
        placeholder="分数"
      ></a-input>
      <a-button @click="search()" size="small" style="margin-left: 10px"
        >搜索</a-button
      >
    </div>
    <!--  -->
    <div class="body">
      <a-table
        @change="changePage"
        :pagination="pagination"
        :columns="columns"
        :data-source="list"
        :rowKey="(record, n) => record.id || n"
      >
        <span slot="coinName">
          {{ coinName }}
        </span>
        <span slot="versionName">
          {{ versionName }}
        </span>
        <!-- pc记录数 -->
        <span slot="pcScoreCount" slot-scope="val" style="color:#1890ff">
          {{ val }}
        </span>
        <!-- 公博记录数 -->
        <span slot="gbScoreCount" slot-scope="val" style="color:teal">
          {{ val }}
        </span>
        <span slot="type" slot-scope="val">
          {{ val == 0 ? "PCGS,公博" : (val == 1 ? "PCGS" : "公博")}}
        </span>
        <span slot="displayType" slot-scope="val" :style="val == 0 ? 'color:green;' : 'color:red;'">
          {{val == 0 ? '曲线' : '散点'}}
        </span>
        <!-- 操作 -->
        <span slot="action" slot-scope="row">
          <a class="action-item" :style="row.displayType == 0 ? 'color:red;' : 'color:green;'" @click="editScore(row)">{{row.displayType == 0 ? "改散点": "改曲线"}}</a>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 20,
      },
      list: [],
      bigList: [],
      smallList: [],
      editId: "",
      versionName: "",
      coinName: "",
      columns: [
        {
          title: "大币种",
          scopedSlots: { customRender: "coinName" }
        },
        {
          title: "版别名称",
          scopedSlots: { customRender: "versionName" }
        },
        {
          title: "分数",
          dataIndex: "scoreName",
        },
        {
          title: "PC记录数",
          dataIndex: "pcScoreCount",
          scopedSlots: { customRender: "pcScoreCount" }
        },
        {
          title: "公博记录数",
          dataIndex: "gbScoreCount",
          scopedSlots: { customRender: "gbScoreCount" }
        },
        {
          title: "评级公司",
          dataIndex: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "展示类型",
          dataIndex: "displayType",
          scopedSlots: { customRender: "displayType" },
        },
        {
          title: "操作",
          scopedSlots: { customRender: "action" },
        },
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
    };
  },
  created() {
    this.getSelectList();
    // this.getList();
  },
  methods: {
    async editScore(row){
      const res = await this.axios("/dq_admin/coinSilverVersionGoods/editDisplayType", {
        params: {
          id: row.id,
          displayType: row.displayType == 0 ? 1 : 0
        },
      });
      if (res.status != 200) return
      this.$message.success("操作成功")
      this.getList()
    },
    // 搜索
    search(){
      this.params.pageNum = 1
      this.pagination.current = 1;
      this.getList()
    },
    async getList() {
      if (!this.params.versionId) return this.$message.info("请选择版别")
      
      const res = await this.axios("/dq_admin/coinSilverVersionGoods/list", {
        params: this.params,
      });
      this.list = res.data.records;
      this.pagination.total = res.data.total;
      const coin = this.bigList.find(el=>{
        return el.kindId == this.params.kindId
      })
      this.coinName = coin.kindName
      const version = this.smallList.find(el=>{
        return el.versionId == this.params.versionId
      })
      this.versionName = version.versionName
    },

    async changeVersionlist() {
      delete this.params.versionId;
      this.getVersitionList("");
    },
    // 大币种
    async getSelectList() {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getSliverKind"
      );
      if (res.status == 200) {
        const { data } = res;
        this.bigList = data;
      }
    },
    async getVersitionList(str) {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getSliverVersion",
        {
          params: { keyword: str, kindId: this.params.kindId },
        }
      );
      if (res.status == 200) {
        const { data } = res;
        this.smallList = data;
      }
    },
    selectVersion(id) {
      this.search()
    },
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  overflow-y: scroll;
}
</style>